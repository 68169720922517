import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import './ModalTable.css'; // Import the CSS file for styles

const ModalTable = ({ isOpen, onRequestClose, title, data }) => {

  const [items, setItems] = useState([]);
  const [columns, setColumns] = useState([]);


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    setItems(data)
    const fieldNames = Object.keys(data[0]);

    const columns = fieldNames.map((field, index) => ({
      name: capitalizeFirstLetter(field),  // Dynamic column name
      selector: row => row[field],    // Selector function to access the field
      sortable: true,                 // Make the column sortable
    }));

    setColumns(columns)

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onRequestClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onRequestClose]);


  if (!isOpen) return null; // Do not render if modal is not open 

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {title && <h2 className="modal-title">{title}</h2>}
        <DataTable
          columns={columns}
          data={items}
          pagination
          highlightOnHover
          striped
        />
        <button onClick={onRequestClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default ModalTable;
